<template>
  <div
    class="pt-8 absolute top-0 px-4 h-screen flex flex-col justify-between bg-dark border-l-0-5 border-gray-600 text-white transition-all duration-500 transform overflow-y-auto"
    @click.prevent
  >
    <div class="flex flex-col">
      <div
        v-if="filteredList?.name"
        class="mb-4 ml-1 bg-dark font-semibold text-base text-gray-100"
      >
        {{ filteredList.name }}
      </div>
      <div class="w-59">
        <search-input
          v-model="searchTerm"
          :placeholder="placeholder"
          dark-theme
          small-size
          clear-button-enabled
        />
      </div>
      <div v-for="(option, i) in filteredList?.items" :key="i">
        <router-link
          :to="{ path: option.link, query: { stage: option.status.stageName } }"
        >
          <div
            class="flex flex-col justify-start font-medium text-gray-300 p-2 cursor-pointer hover:bg-gray-600 space-y-2 border-t-0-5 border-b-0-5 border-gray-600 max-w-63"
          >
            <span class="overflow-hidden truncate">{{ option.label }}</span>
            <status-label :status="option.status" />
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import { ref, computed } from "vue";

import type { DealStatusRecord } from "@/models/state";

import StatusLabel from "@/components/ui/StatusLabel.vue";

export interface IRecentListItem {
  label: string;
  link: string;
  status: DealStatusRecord;
}

export interface IRecentList {
  name: string;
  items: IRecentListItem[];
}

const props = defineProps({
  recentList: {
    type: Object as PropType<IRecentList | null>,
    default: null
  },
  placeholder: {
    type: String,
    default: "Search"
  }
});

const searchTerm = ref("");

const filteredList = computed(() => {
  if (!searchTerm.value) {
    return props.recentList;
  }
  return {
    ...props.recentList,
    items: props.recentList?.items.filter((item) =>
      item.label.toLowerCase().includes(searchTerm.value.toLowerCase())
    )
  };
});
</script>
